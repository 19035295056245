import React from "react";
import style from "./Slogan.module.css";

export const Slogan = () => {
    return (
        <div className={style.wrapper}>
            <div className={style.container}>
                <h1 className={style.centerParag}>СОЗДАЕМ</h1>
                <h1>ВАШ ИДЕАЛЬНЫЙ МОМЕНТ</h1>
                <div className={style.subtitle}>
                    <p>О НАС</p>
                    <div className={style.content}>
                        <p>
                            У нас не стандартный и шаблонный подход к
                            организации мероприятий: мы тщательно продумываем
                            идею, основываясь не только на информации от
                            заказчика и его бюджета, но и проводя собственные
                            исследования для организации крутого ивента,
                            удовлетворяющего потребности и цели клиента и гостей
                            мероприятия.
                        </p>
                        <p>
                            Наша цель - не просто организация конференции,
                            презентации или торжества с большим экраном,
                            огромным количеством света, баннером и диджеем. МЫ
                            ОРГАНИЗОВЫВАЕМ ИВЕНТЫ, РЕЩАЮЩИЕ ГЛАВНЫЕ ЗАДАЧИ
                            КЛИЕНТА.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
